import request from '@/utils/http'

/**
 * 应用列表
 * @param payload
 */
export function list(payload) {
    return request({ url: '/client/Index/index', method: 'get', data: payload })
}

/**
 * 创建应用
 * @param payload
 */
export function create(payload) {
    return request({ url: '/client/Index/add', method: 'post', data: payload })
}

/**
 * 微信公众号设置
 * @param payload
 */
export function wechat_save_config(payload) {
    return request({ url: '/client/Wechat/save', method: 'post', data: payload })
}

/**
 * 微信公众号获取配置
 * @param payload
 */
export function wechat_get_config(payload) {
    return request({ url: '/client/Wechat/config', method: 'get', data: payload })
}

/**
 * 微信公众号菜单配置设置
 * @param payload
 */
export function wechat_save_menu(payload) {
    return request({ url: '/client/Wechat/menu_save', method: 'post', data: payload })
}

/**
 * 微信公众号菜单配置获取
 * @param payload
 */
export function wechat_get_menu(payload) {
    return request({ url: '/client/Wechat/menu', method: 'get', data: payload })
}

/**
 * 微信公众号素材删除
 * @param payload
 */
export function wechat_del_material(payload) {
    return request({ url: '/client/Wechat/menu', method: 'get', data: payload })
}

/**
 * 微信公众号素材列表
 * @param payload
 */
export function wechat_list_material(payload) {
    return request({ url: '/client/WechatMaterial/material', method: 'get', params: payload })
}

/**
 * 微信公众号粉丝列表
 * @param payload
 */
export function wechat_list_fans(payload){
    return request({ url: '/client/WechatFans/fans', method: 'get', params: payload })
}

/**
 * 微信公众号粉丝同步
 * @param payload
 */
 export function wechat_sync_fans(payload){
    return request({ url: '/client/WechatFans/syncWechatFans', method: 'post', data: payload })
}

/**
 * 资源上传
 * @param payload
 */
 export function wechat_upload4(payload){
    return request({ url: '/client/WechatMaterial/upload', method: 'post', data: payload ,headers:{'Content-Type':'multipart/form-data'}})
}

/**
 * 模版列表
 * @param payload
 */
export function wechat_list_template(payload){
    return request({ url: '/client/WechatTemplate/lists', method: 'get', params: payload })
}

/**
 * 回复设置列表
 * @param payload
 */
 export function wechat_list_reply(payload){
    return request({ url: '/client/WechatReply/lists', method: 'get', params: payload })
}

/**
 * 回复设置
 * @param payload
 */
 export function wechat_reply_create(payload){
    return request({ url: '/client/WechatReply/create', method: 'post', data: payload })
}

/**
 * 回复设置
 * @param payload
 */
 export function wechat_reply_update(payload){
    return request({ url: '/client/WechatReply/update', method: 'post', data: payload })
}

/**
 * 回复删除
 * @param payload
 */
 export function wechat_reply_del(payload){
    return request({ url: '/client/WechatReply/delete', method: 'get', params: payload })
}

/**
 * 模板开启
 * @param payload
 */
 export function wechat_reply_open(payload){
    return request({ url: '/client/WechatTemplate/open', method: 'post', data: payload })
}

/**
 * 模板test
 * @param payload
 */
 export function wechat_reply_test(payload){
    return request({ url: '/client/WechatTemplate/test', method: 'post', data: payload })
}



/**
 * 上传
 * @param payload
 */
 export let wechat_upload = process.env.VUE_APP_API_BASE_URL + '/client/WechatMaterial/upload';
 /**
 * 上传图文资源
 * @param payload
 */
  export let wechat_upload_image = process.env.VUE_APP_API_BASE_URL + '/client/WechatMaterial/upload_image';
  /**
 * 上传音频资源
 * @param payload
 */
 export let wechat_upload_audio = process.env.VUE_APP_API_BASE_URL + '/client/WechatMaterial/upload_audio';
 /**
 * 上传视频资源
 * @param payload
 */
  export let wechat_upload_video = process.env.VUE_APP_API_BASE_URL + '/client/WechatMaterial/upload_video';